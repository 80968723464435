import React from "react";
import classNames from "classnames";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Carousel = ({ activeImage, setActiveImage, images }) => {
  const handleSetImage = ({ position, index, hasIndex }) => {
    setActiveImage((imageIndex) => {
      if (hasIndex) return index;

      if (position === "left") {
        if (imageIndex === 0) return images?.length - 1;
        return imageIndex - 1;
      }

      if (imageIndex === images?.length - 1) return 0;
      return imageIndex + 1;
    });
  };

  return (
    <div className="carousel">
      {images?.map((image, index) => (
        <img
          aria-label="project image"
          className={classNames("carousel-image carousel-fade", {
            "is-active": activeImage === index,
          })}
          src={image?.childrenImageSharp?.[0]?.fluid?.originalImg}
        />
      ))}
      <div className="carousel-dots">
        {images?.map((_, index) => (
          <button
            aria-label="carousel dot navigation"
            className={classNames("carousel-dot", {
              "is-active": activeImage === index,
            })}
            onClick={() => handleSetImage({ index, hasIndex: true })}
          />
        ))}
      </div>
      <div className="carousel-buttons">
        <button
          className="carousel-left"
          onClick={() => handleSetImage({ position: "left" })}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button
          className="carousel-right"
          onClick={() => handleSetImage({ position: "right" })}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
