import React from "react";
import { gsap, Expo, Power4 } from "gsap";

import Button from "elements/Button";

import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const ProjectCard = ({ project, dispatch, setActiveProject }) => {
  let data = project?.data;

  const handleShowProjectDetails = () => {
    setActiveProject(data);
    dispatch({ type: "TOGGLE_PROJECT_DETAILS" });

    let contactFormTimeline = gsap.timeline();
    let contactFormBackgroundTimeline = gsap.timeline();

    contactFormTimeline.to(".project-details-container", {
      duration: 0.5,
      height: "90vh",
      ease: Power4.easeOut,
    });

    contactFormTimeline.from(".details-content", {
      ease: Power4.easeOut,
      duration: 0.5,
      opacity: 0,
      y: 20,
    });

    contactFormBackgroundTimeline.to(".project-details", {
      ease: Expo.easeOut,
      duration: 1,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    });
  };

  return (
    <div className="project-card has-text-left">
      <img
        aria-label="project thumbnail"
        className="project-thumbnail"
        src={
          data?.Thumbnail?.localFiles?.[0]?.childImageSharp?.fluid?.originalImg
        }
      />
      <div className="project-info">
        <p className="project-name">{data?.Name}</p>
        <p className="project-intro">{data?.Intro}</p>
        <div className="project-tags">
          {data?.Tags?.map((tag) => (
            <span className="tag is-primary">{tag}</span>
          ))}
        </div>
        <Button
          iconRight
          size="small"
          color="primary"
          text="Learn More"
          icon={faArrowRightLong}
          className="project-learn-more"
          handleClick={handleShowProjectDetails}
        />
      </div>
    </div>
  );
};

export default ProjectCard;
