import React from "react";

import Layout from "layouts/Layout";
import Projects from "../components/Projects";

const ProjectsPage = () => {
  return (
    <Layout seoTitle="Projects">
      <Projects />
    </Layout>
  );
};

export default ProjectsPage;
