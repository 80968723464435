import classNames from "classnames";
import React, { useState } from "react";
import { gsap, Expo, Power4 } from "gsap";

import Carousel from "elements/Carousel";

const ProjectDetail = ({
  state,
  dispatch,
  activeProject,
  setActiveProject,
}) => {
  const [activeImage, setActiveImage] = useState(0);

  const handleToggleForm = () => {
    setActiveImage(0);

    let detailContentTimeline = gsap.timeline();
    let detailBackgroundTimeline = gsap.timeline();

    detailContentTimeline.to(".details-content", {
      ease: Power4.easeOut,
      duration: 0.3,
      opacity: 0,
      y: 20,
    });

    detailContentTimeline.to(".project-details-container", {
      duration: 0.5,
      height: "0rem",
      ease: Expo.easeOut,
    });

    detailContentTimeline.to(".details-content", {
      duration: 0,
      opacity: 1,
      y: 0,
    });

    detailBackgroundTimeline.to(".project-details", {
      duration: 1,
      backgroundColor: "rgba(1, 1, 1, 0)",
    });

    setTimeout(() => {
      setActiveProject({});

      dispatch({ type: "TOGGLE_PROJECT_DETAILS" });
    }, 250);
  };

  return (
    <div
      className={classNames("project-details", {
        "is-active": state?.isProjectDetailsActive,
      })}
    >
      <div className="project-details-container">
        <button
          className="close-button delete"
          onClick={handleToggleForm}
          aria-label="close project details"
        />
        <div className="details-content">
          <Carousel
            activeImage={activeImage}
            setActiveImage={setActiveImage}
            images={activeProject?.Images?.localFiles}
          />
          <div className="project-details-content">
            <h1 style={{ color: activeProject?.Color }}>
              {activeProject?.Name}
            </h1>
            <div className="project-tags">
              {activeProject?.Tags?.map((tag) => (
                <span
                  style={{
                    borderColor: activeProject?.Color,
                    backgroundColor: activeProject?.Color,
                  }}
                  className="tag is-primary"
                >
                  {tag}
                </span>
              ))}
            </div>
            <div
              className="project-description"
              dangerouslySetInnerHTML={{ __html: activeProject?.Description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
