import { gsap } from "gsap";
import lodash from "lodash";
import React, { useEffect, useContext, useState } from "react";

import ProjectCard from "./components/ProjectCard";
import ProjectDetail from "./components/ProjectDetail";

import "./style/projects.scss";
import { useProjects } from "./hooks/useProjects";
import { AppContext } from "../../context/AppContext";

const Projects = () => {
  const { state, dispatch } = useContext(AppContext);
  const [activeProject, setActiveProject] = useState({});

  let projects = useProjects();
  let chunkedProjects = lodash.chunk(projects?.allAirtableProjects?.nodes, 3);

  useEffect(() => {
    let projectCardsTimeline = gsap.timeline();
    let pageContentTimeLine = gsap.timeline();

    projectCardsTimeline.from(".project-card", {
      duration: 0.4,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      stagger: 0.2,
      opacity: 0,
      y: 40,
    });

    pageContentTimeLine.from(".project-title", {
      duration: 0.5,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      opacity: 0,
      y: 20,
    });

    //eslint-disable-next-line
  }, []);

  return (
    <div className="projects-page-container has-text-centered">
      <h1 className="title project-title">Projects</h1>
      <ProjectDetail
        state={state}
        dispatch={dispatch}
        activeProject={activeProject}
        setActiveProject={setActiveProject}
      />
      <div className="projects-card-container">
        {chunkedProjects?.map((projects) => (
          <div className="columns">
            {projects?.map((project) => (
              <div className="column">
                <ProjectCard
                  setActiveProject={setActiveProject}
                  dispatch={dispatch}
                  project={project}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
