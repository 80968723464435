import { graphql, useStaticQuery } from "gatsby";

export const useProjects = () => {
  let projectsQuery = graphql`
    query projectsQuery {
      allAirtableProjects(sort: { fields: data___Order, order: ASC }) {
        nodes {
          data {
            Intro
            Tags
            Name
            Color
            Description
            Thumbnail {
              localFiles {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
            Images {
              localFiles {
                childrenImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return useStaticQuery(projectsQuery);
};
